import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"
import BackgroundImage from 'gatsby-background-image'

const ContentPageIndex = () => {
  const data = useStaticQuery(graphql`
    query {
      rando: file(relativePath: { eq: "mascotteTourisme.png" }) {
        childImageSharp {
          fixed(width: 260, height: 272) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      music: file(relativePath: { eq: "mascotteMusique.png" }) {
        childImageSharp {
          fixed(width: 260, height: 280) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      affiche1: file(relativePath: { eq: "picots-herisson-2022.jpg" }) {
        childImageSharp {
          fluid (maxHeight: 500) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      affiche2: file(relativePath: { eq: "baume-messieurs-2022.jpg" }) {
        childImageSharp {
          fluid (maxHeight: 500) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      bgrando: allFile (
          filter: {relativePath: {regex: "/bg-rando/"}}
          sort: { fields: [relativePath] }
        ) {
        nodes {
          childImageSharp {
            fluid (maxHeight: 500) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      bgmusic: allFile (
        filter: {relativePath: {regex: "/bg-music/"}}
        sort: { fields: [relativePath] }
      ) {
      nodes {
        childImageSharp {
          fluid (maxHeight: 500) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
    }
  `)
  return (
    <>
      <div className="home columns pt-5 is-variable is-7">
        <div className="column">
          <div className="has-text-centered">
            <Img
              fixed={data.rando.childImageSharp.fixed}
              alt="Tourisme dans le Jura"
            />
          </div>
          <h2 className="has-text-centered">Excursions et randonnées</h2>
          <p>
            Je souhaiterais vous faire découvrir les{" "}
            <strong>
              endroits insolites ou incontournables{" "}
              <a href="https://fr.wikipedia.org/wiki/Jura_(d%C3%A9partement)">
                du Jura
              </a>
            </strong>
            . Cela se fera par le biais d’
            <Link to="/fr/accompagnateur-de-tourisme/#excursions">
              excursions
            </Link>{" "}
            ou de{" "}
            <Link to="/fr/accompagnateur-de-tourisme/#randonnee">
              randonnées
            </Link>{" "}
            accompagnées et adaptées à vos attentes. Il est agréable de{" "}
            <strong>
              prendre le temps pour découvrir de nouveaux paysages, faire des
              rencontres, connaître des artisans
            </strong>{" "}
            qui sauront vous faire partager leurs savoir faire et le goût du{" "}
            <Link to="/fr/accompagnateur-de-tourisme/#terroir">
              terroir jurassien
            </Link>
            .
          </p>
        </div>
        <div className="column is-hidden-tablet-only is-hidden-mobile"> 
          <div className="slider">
            {data.bgrando.nodes.map(bg => (
              <BackgroundImage fluid={bg.childImageSharp.fluid} />
            ))}
          </div>
        </div>
      </div>
      <div className="home columns pt-5 is-variable is-7">
        <div className="column is-hidden-tablet-only is-hidden-mobile">
          <div className="slider">
            {data.bgmusic.nodes.map(bg => (
              <BackgroundImage fluid={bg.childImageSharp.fluid} />
            ))}
          </div>
        </div>
        <div className="column">
          <div className="has-text-centered">
            <Img
              fixed={data.music.childImageSharp.fixed}
              alt="Animation musique"
            />
          </div>
          <h2 className="has-text-centered">Animations musicales</h2>
          <p>
            Je vous propose des{" "}
            <Link to="/fr/animation_musicale">animations musicales</Link> comme
            des{" "}
            <Link to="/fr/animation_musicale#soiree_danse">
              soirées dansantes
            </Link>
            , <Link to="/fr/animation_musicale#karaoke">karaoké</Link> ou{" "}
            <Link to="/fr/animation_musicale#thes">thés</Link> dansants. Que ce
            soit lors de vos évènements privés comme votre{" "}
            <Link to="/fr/animation_musicale#mariage">mariage</Link> ou un
            anniversaire ou publics pour votre association ou structure. Tout
            cela vous est proposé avec un service et la qualité d’un matériel
            professionnel adapté à vos besoins.
          </p>
        </div>
      </div>
      <div className="columns">
        <div className="column">
          <Img
            fluid={data.affiche1.childImageSharp.fluid}
            alt="Les picôts du hérisson"
            style={{ maxWidth: 500, width: "100%" }}
          />
        </div>
        <div className="column">
          <Img
            fluid={data.affiche2.childImageSharp.fluid}
            alt="Baume les Messieurs"
            style={{ maxWidth: 500, width: "100%" }}
          />
        </div>
      </div>
    </>
  )
}

export default ContentPageIndex
