import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ContentPageIndex from "../components/contentPageIndex"

const IndexPageFr = ({location}) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteUrl
          }
        }
      }
    `
  )
  return (
    <Layout location={location}>
      <SEO
        title="Accueil"
        link={[{ rel: "canonical", href: site.siteMetadata.siteUrl}]}
        description="Excursions et randonnées dans le Jura. Animation musicale : soirées dansantes, karaokés, ..."
      />
      <ContentPageIndex />
    </Layout>
  )
}

export default IndexPageFr
